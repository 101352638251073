import React from "react";
import Contact from "../components/contact";
function ContactPage() {
  return (
    <div id="contactbg">
      <Contact></Contact>
    </div>
  );
}

export default ContactPage;
